import { Box } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";

function OrderQrcode({ orderNo }) {
    return (
        <Box sx={{ display: "none" }}>
            <QRCodeCanvas id="certQr" value={orderNo} />
        </Box>
    );
}

export default OrderQrcode;
