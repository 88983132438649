import React from 'react';
import { Box, styled } from '@mui/material';
import { colors } from '../assets/utils/colors';
import logo2 from '../assets/media/images/logo2.png';
import logo from '../assets/media/images/logo1.png';
import Loader from 'react-js-loader';

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const FullAppLoader = () => {
    return (
        <>
            <Box>
                <PageBgImageCon>
                    <img src={logo2} alt="" style={{ opacity: 0.04 }} />
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            <img
                                src={logo}
                                alt=""
                                style={{ opacity: 0.8, width: '70%' }}
                            />
                            <Box sx={{ position: 'absolute', top: `70%` }}>
                                <Loader
                                    type="bubble-loop"
                                    bgColor={colors.primary}
                                    color={colors.primary}
                                    size={100}
                                />
                            </Box>
                        </Box>
                    </Box>
                </PageBgImageCon>
            </Box>
        </>
    );
};

export default FullAppLoader;
