import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import logo from "../../assets/media/images/logo.png";
import appQr from "../../assets/media/images/qr.png";
import logo2 from "../../assets/media/images/logo2.png";
import facebook from "../../assets/media/images/facebook.png";
import instagram from "../../assets/media/images/instagram.png";
import phone from "../../assets/media/images/phone.png";
import email from "../../assets/media/images/email.png";
import global from "../../assets/media/images/global.png";
import { colors } from "../../assets/utils/colors";
import moment from "moment/moment";
import { isEmpty } from "lodash";

// Create styles
const styles = StyleSheet.create({
    page: {
        margin: "0 auto",
        backgroundColor: colors.bgColor2,
    },
    image: {
        height: "120px",
        width: "180px",
    },
    contactHead: {
        fontSize: 9,
        fontWeight: "bold",
        color: colors.bgColor2,
    },
});

// Create Document Component
const ShippingCoverPDF = ({ data, deliveryData }) => {
    // ###### Distructure orders data #########
    const { customer } = data;
    const { ordered_products: products } = data;
    const { shipping_address: shippingAddress } = data;
    const { order_number: orderID } = data;

    const shippingDetails = [
        {
            id: 1,
            title: "Receiver details",
            height: `100%`,
            details: [
                {
                    id: 1,
                    detail: `${customer.first_name} ${customer.last_name}`,
                },
                {
                    id: 2,
                    detail: `${customer.city}, ${customer.country}`,
                },
                {
                    id: 3,
                    detail: `${customer.phone_number}`,
                },
            ],
        },
        {
            id: 2,
            title: "Destination",
            height: `100%`,
            details: [
                {
                    id: 2,
                    detail: shippingAddress,
                },
            ],
        },
        {
            id: 3,
            height: 25,
            title: "Delivery date",
            details: [
                {
                    id: 1,
                    detail: !isEmpty(deliveryData.delivery_date)
                        ? moment(deliveryData?.delivery_date).format("ll")
                        : "",
                },
            ],
        },
        {
            id: 4,
            height: "100%",
            title: "Item list",
            details: products,
        },
        {
            id: 5,
            height: `100%`,
            title: "Other information",
            details: [
                {
                    id: 1,
                    detail: "OrderID",
                },
                {
                    id: 2,
                    detail: orderID,
                },
            ],
        },
    ];

    const qrCodeCanvas = document.getElementById("certQr");
    const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);

    return (
        <Document>
            <Page
                size={
                    deliveryData.pdf_size === "medium"
                        ? [500, 500]
                        : deliveryData.pdf_size === "small"
                        ? [400, 400]
                        : "A4"
                }
                style={styles.page}
            >
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        height: "100%",
                        backgroundColor: colors.primary,
                        borderTop: `5px solid ${colors.primary}`,
                        borderRight: `5px solid ${colors.primary}`,
                        borderLeft: `5px solid ${colors.primary}`,
                        borderBottom: `5px solid ${colors.primary}`,
                    }}
                >
                    <View
                        style={{
                            width: "30%",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: colors.primary,
                        }}
                    >
                        <View
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 15,
                            }}
                        >
                            <Image src={logo} style={{ width: "100%" }} />
                        </View>
                        <View
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 10,
                            }}
                        >
                            <View
                                style={{
                                    marginBottom: 20,
                                    border: `4px solid ${colors.bgColor1}`,
                                    borderRadius: 10,
                                    overflow: "hidden",
                                    backgroundColor: colors.bgColor2,
                                }}
                            >
                                <Image src={appQr} style={{ width: "100%" }} />
                            </View>
                            <View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        height: 15,
                                    }}
                                >
                                    <Image
                                        src={phone}
                                        style={{
                                            width: 10,
                                            height: 10,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Text style={styles.contactHead}>
                                        0676255678
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        height: 15,
                                    }}
                                >
                                    <Image
                                        src={facebook}
                                        style={{
                                            width: 10,
                                            height: 10,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Image
                                        src={instagram}
                                        style={{
                                            width: 10,
                                            height: 10,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Text style={styles.contactHead}>
                                        agizaafrica
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        height: 15,
                                    }}
                                >
                                    <Image
                                        src={global}
                                        style={{
                                            width: 10,
                                            height: 10,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Text style={styles.contactHead}>
                                        www.agiza.africa
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        height: 15,
                                    }}
                                >
                                    <Image
                                        src={email}
                                        style={{
                                            width: 10,
                                            height: 10,
                                            marginRight: 2,
                                        }}
                                    />
                                    <Text style={styles.contactHead}>
                                        info@agiza.africa
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            width: "70%",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            borderTopLeftRadius: 50,
                            borderBottomLeftRadius: 100,
                            overflow: "hidden",
                            paddingLeft: 20,
                            paddingRight: 20,
                            backgroundColor: colors.bgColor2,
                        }}
                    >
                        <View
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: 0,
                            }}
                        >
                            <Image
                                style={{ opacity: 0.05, width: "70%" }}
                                src={logo2}
                            />
                        </View>
                        <View
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                borderRadius: 5,
                                border: `1px solid ${colors.primary}`,
                                borderBottom: `5px solid ${colors.primary}`,
                            }}
                        >
                            <View
                                style={{
                                    width: "40%",
                                    height: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRight: `1px solid ${colors.primary}`,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 6.5,
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: colors.primary,
                                        textAlign: "center",
                                    }}
                                >
                                    Delivery company
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: "60%",
                                    height: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 9,
                                        fontWeight: "bold",
                                        padding: 5,
                                        color: colors.primary,
                                    }}
                                >
                                    {deliveryData?.delivery_company}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={{
                                marginTop: 25,
                                marginBottom: 25,
                                borderTop: `1px solid ${colors.primary}`,
                                borderLeft: `1px solid ${colors.primary}`,
                                borderRight: `1px solid ${colors.primary}`,
                            }}
                        >
                            {shippingDetails.map((details) => (
                                <View
                                    key={details.id}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: `1px solid ${colors.primary}`,
                                    }}
                                >
                                    <View
                                        style={{
                                            width: "40%",
                                            height: details.height,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRight: `1px solid ${colors.primary}`,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 6.5,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                                color: colors.primary,
                                                textAlign: "center",
                                            }}
                                        >
                                            {details.title}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: "60%",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            padding: 5,
                                        }}
                                    >
                                        {details.title === "Item list"
                                            ? details.details.map(
                                                  (product, index) => (
                                                      <Text
                                                          key={index}
                                                          style={{
                                                              fontSize: 8,
                                                              marginTop: 2,
                                                              marginBottom: 2,
                                                              fontWeight:
                                                                  "bold",
                                                              color: colors.primary,
                                                          }}
                                                      >
                                                          {`- ${product.title}`}
                                                      </Text>
                                                  )
                                              )
                                            : details.details?.map((detail) => (
                                                  <Text
                                                      style={{
                                                          fontSize: 8,
                                                          marginTop: 2,
                                                          marginBottom: 2,
                                                          fontWeight: "bold",
                                                          color: colors.primary,
                                                      }}
                                                  >
                                                      {detail.detail}
                                                  </Text>
                                              ))}
                                        {details.title ===
                                            "Other information" && (
                                            <View
                                                style={{
                                                    position: "absolute",
                                                    right: 5,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        height: 28,
                                                        width: 28,
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Image
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        source={{
                                                            uri: qrCodeDataUri,
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            ))}
                        </View>
                        <View
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                borderRadius: 5,
                                border: `1px solid ${colors.primary}`,
                                borderBottom: `5px solid ${colors.primary}`,
                            }}
                        >
                            <View
                                style={{
                                    width: "40%",
                                    height: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRight: `1px solid ${colors.primary}`,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 6.5,
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: colors.primary,
                                        textAlign: "center",
                                        marginBottom: 2,
                                    }}
                                >
                                    Customer
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 6.5,
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: colors.primary,
                                        textAlign: "center",
                                    }}
                                >
                                    Signature
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: "60%",
                                    height: 30,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                        color: colors.primary,
                                    }}
                                ></Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};
export default ShippingCoverPDF;
