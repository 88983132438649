import { DoneAll } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../assets/utils/colors';

const SuccessFeedback = ({ message, height }) => {
    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: height ? height : 250,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <DoneAll sx={{ fontSize: 80, color: colors.green, mb: 2 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        color: colors.primary,
                        opacity: 0.8,
                    }}
                >
                    {message}
                </Typography>
            </Box>
        </>
    );
};

export default SuccessFeedback;
