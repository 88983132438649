import React from 'react';

// =============== MUI import ==============
import { Cloud } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';

// =============== Asset import ==============
import { colors } from '../../assets/utils/colors';
import { radius } from '../../assets/utils/radius';
import { useDate } from '../../hooks/useDate';

// ============= Styled MUI ==================
const TimeCard = styled(Box)((theme) => ({
    'bgcolor': colors.bgColor3,
    'display': 'flex',
    'paddingTop': 25,
    'paddingBottom': 25,
    'paddingLeft': 15,
    'paddingRight': 15,
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'width': 180,
    'height': 40,
    'border': '1px solid #fff',
    'borderRadius': radius.cardRadius,
    '& .date': {
        fontSize: 11,
        mb: 0.1,
        color: '#000',
        fontStyle: 'italic',
    },
    '& .time': {
        fontSize: 11,
        mt: 0.1,
        color: '#000',
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    '& .icon-con': {
        'padding': 2,
        'width': 40,
        'height': 40,
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'borderRadius': 50,
        'backgroundImage': `linear-gradient(180deg, ${colors.primary}, ${colors.bgColor1})`,
        '& .moon': {
            width: 15,
            height: 15,
            background: colors.yellow,
            position: 'absolute',
            top: 8,
            left: 8,
            borderRadius: 50,
            zIndex: 1,
        },
    },
}));

// !################## MAIN FUNC ####################
const TimeWidget = () => {
    const date = useDate();
    return (
        <TimeCard>
            <Box>
                <Typography className="date">{date.date2}</Typography>
                <Typography className="time">{date.time}</Typography>
            </Box>
            <Box sx={{ position: 'relative' }}>
                <Box className="icon-con">
                    <Cloud sx={{ zIndex: 2 }} />
                    <Box className="moon"></Box>
                </Box>
            </Box>
        </TimeCard>
    );
};

export default TimeWidget;
