import React from 'react';

const useNetwork = () => {
    const [isOnline, setNetwork] = React.useState(window.navigator.onLine);

    React.useEffect(() => {
        window.addEventListener('offline', () =>
            setNetwork(window.navigator.onLine),
        );

        window.addEventListener('online', () =>
            setNetwork(window.navigator.onLine),
        );
    });

    return isOnline;
};

export default useNetwork;
